<template>
  <div style="padding: 100px;">
    <input type="file" ref="fileInput" @change="loadDocument">
    <div ref="editor" style="height: 500px;"></div>
    <button v-if="documentHtml" @click="saveDocument">保存</button>
  </div>
</template>

<script>
/* eslint-disable */
  import 'quill/dist/quill.core.css'
  import 'quill/dist/quill.snow.css'
  import 'quill/dist/quill.bubble.css'
  import Quill from 'quill'
  import mammoth from 'mammoth'

  export default {
    data() {
      return {
        documentHtml: null,
        editor: null
      }
    },
    methods: {
      // 加载Word文档并将其转换为HTML格式
      loadDocument() {
        const file = this.$refs.fileInput.files[0]
        mammoth.convertToHtml({
            arrayBuffer: file
          })
          .then(result => {
            this.documentHtml = result.value
          })
      },
      // 初始化Quill编辑器并加载HTML内容
      initEditor() {
        this.editor = new Quill(this.$refs.editor, {
          modules: {
            toolbar: [
              ['bold', 'italic', 'underline', 'strike'],
              [{
                'color': []
              }, {
                'background': []
              }],
              [{
                'header': [1, 2, 3, 4, 5, 6, false]
              }],
              [{
                'align': []
              }],
              [{
                'list': 'ordered'
              }, {
                'list': 'bullet'
              }],
              ['link', 'image']
            ]
          },
          theme: 'snow'
        })
        this.editor.root.innerHTML = this.documentHtml
      },
      // 将编辑器内容转换为Word文档并保存
      saveDocument() {
        // 获取编辑器内容
        const content = this.editor.root.innerHTML;

        // 创建Blob对象
        const blob = new Blob([content], {
          type: 'application/msword'
        });

        // 创建文件下载链接并点击下载
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'document.doc';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    watch: {
      documentHtml() {
        this.initEditor()
      }
    }
  }
  /* eslint-enable */

</script>

<style>
  .ql-toolbar.ql-snow {
    border: none;
    border-bottom: 1px solid #ccc
  }

</style>
